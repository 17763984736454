:root {
  --primary: #280D3D;
  --secondary: #8468ce;
  --gold: linear-gradient(45deg, #d4a86a, #f7e198, #d4a86a, #f7e198)
}

* {
  font-family: 'Brandon Grotesque', sans-serif;
  font-weight: normal;
}

@font-face {
  font-family: 'Geogrotesque';
  src: url('./images/fonts/Geogrotesque-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Geogrotesque';
  src: url('./images/fonts/Geogrotesque-SemiBold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('./images/fonts/HvDTrial_Brandon_Grotesque_regular-BF64a625c9311e1.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('./images/fonts/HvDTrial_Brandon_Grotesque_medium-BF64a625c84a521.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Brandon Grotesque';
  src: url('./images/fonts/HvDTrial_Brandon_Grotesque_bold-BF64a625c9151d5.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
